import { graphql, navigate } from 'gatsby';
import React, { Component } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import Layout from '../components/layout';
import Footer from '../components/footer';
import Hero from '../components/hero';
import ImageUtils from '../utils/image-utils';
import genericImage from '../styles/img/stock/shutterstock_409251190.jpeg';
import { DownloadContentForm } from '../components/downloads/download-content-form';
import LocalizationService from '../services/localization-service';
import { STATIC_IMAGES } from '../resources/references';

export default class DownloadPage extends Component {

  state = {
    error: null
  };

  closeDialog = () => {
    this.setState({ error: null });
  };

  onDownloadRequestError = (error) => {
    console.error(error);
    this.setState({ error });
  };

  onDownloadRequestSuccess = () => {
    const { downloadContentResource } = this.props.data;
    const { contentful_id: contentId } = downloadContentResource;
    navigate(`/thank-you/for-downloading?cid=${contentId}`);
  };

  render = () => {

    const { downloadContentResource } = this.props.data;
    const { contentful_id: contentId, name: resourceName, resources } = downloadContentResource;
    const localizationService = new LocalizationService(resources);

    console.log('this.props.data', this.props.data);

    const previewImage = localizationService.getString(`${resourceName}.preview`);

    return <div className="download-page">
      <Layout>
        <div className="">
          <section className="bg-brand-neutral-3 py-4 text-white">
            <div className="container">
              <div className="row g-5">
                <div className="col-12 text-center">
                  <h2 className="mb-0 pb-0">{localizationService.getValue(`${resourceName}.title`)}</h2>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-brand-neutral-2 py-4 text-white">
            <div className="container">
              <div className="row g-5">
                <div className="col-12 text-center text-md-start">
                  <p className="lead m-0 p-0 text-brand-neutral-3">{localizationService.getValue(`${resourceName}.summary`)}</p>
                </div>
              </div>
            </div>
          </section>
          <div className="container">
            <Hero
              cta={<ScrollLink className="btn btn-lg btn-brand-cta-1" offset={-60} to="download_section">Download</ScrollLink>}
              image={previewImage ? ImageUtils.staticToGatsbyImage(STATIC_IMAGES[previewImage]) : genericImage}
              imageAltText={localizationService.getString(`${resourceName}.hero.image_alt_text`, 'Downloads')}
              options={{ contentClassNames: 'pe-md-5', imageClassNames: 'ms-md-5' }}
              raw={<>
                {/* <h2>{localizationService.getValue(`${resourceName}.content.title`, 'Document')}</h2> */}
                <DownloadContentForm contentId={contentId} onError={this.onDownloadRequestError} onSuccess={this.onDownloadRequestSuccess} />
                <p className="m-0 mt-3">{localizationService.getValue(`${resourceName}.description`, 'Download your document')}</p>
              </>}
            />
          </div>
        </div>
        <Footer />
      </Layout>
      <div className={`modal fade show ${this.state.error ? 'd-block' : ''}`} id="exampleModalLive" tabindex="-1" aria-labelledby="exampleModalLiveLabel" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Could not submit request!</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.closeDialog}></button>
            </div>
            <div className="modal-body">
              <p>{this.state.error}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.closeDialog}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>;
  };
};

export const query = graphql`
  query DownloadPageQuery($resourceName: String!) {
    downloadContentResource: contentfulResourceSet(name: {eq: $resourceName}) {
      contentful_id
      id
      name
      resources {
        key
        value {
          value
        }
        isHtml
      }
    }
  }
`;